import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactNode, useEffect } from "react";
import { useToggle } from "react-use";
import downIcon from "./assets/down-arrow.svg";
import upIcon from "./assets/up-arrow.svg";

const Wrapper = styled.div<{ active: boolean }>`
  margin-bottom: 1.5rem;
  border: 1px solid ${(props) => (props.active ? "#7db8e5" : "#e5e5e5")};
  border-radius: 8px;
  font-weight: 400;
`;

const Header = styled.div<{ variant: "medium" | "large" }>`
  padding: ${(props) => (props.variant === "medium" ? "16px" : "24px")} 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 24px;
  background-color: #f5f5f5;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const HeaderIcon = styled.div`
  margin-right: 24px;
  img {
    display: block;
  }
`;

const Accordian = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 20px;
`;

type CardProps = {
  title: ReactNode;
  // Places icon to the left of the title
  headerIcon?: ReactNode;
  // Places the passed element in the top right of the header
  extraTitleContent?: ReactNode;
  // Places the passed element below the title and extraTitleContent
  subtitle?: ReactNode;
  variant?: "medium" | "large";
  children: React.ReactNode;
  accordion?: boolean;
  accordianText?: React.ReactNode;
  className?: string;
  active?: boolean;
  // Determines whether card content is showing
  isOpen?: boolean;
};

export function Card({
  title,
  extraTitleContent,
  subtitle,
  variant = "medium",
  children,
  accordion = false,
  accordianText,
  className,
  active = false,
  headerIcon,
  isOpen,
  ...rest
}: CardProps) {
  const [isOpenInternal, toggleAccordion] = useToggle(
    isOpen !== undefined ? isOpen : true
  );

  useEffect(() => {
    if (isOpen !== undefined) {
      toggleAccordion(isOpen);
    }
  }, [isOpen]);

  return (
    <Wrapper {...rest} tabIndex={0} active={active}>
      <Header variant={variant}>
        <Box
          display="flex"
          {...(subtitle
            ? { flexDirection: "column" }
            : { alignItems: "center" })}
        >
          {headerIcon ? <HeaderIcon>{headerIcon}</HeaderIcon> : null}
          <Typography
            variant={variant === "medium" ? "mHeading" : "lHeading"}
            component="h2"
          >
            {title}
          </Typography>
          {subtitle && <div>{subtitle}</div>}
        </Box>
        {(extraTitleContent || accordion) && (
          <div>
            {extraTitleContent && <div>{extraTitleContent}</div>}
            {accordion && (
              <Accordian onClick={toggleAccordion}>
                {accordianText}{" "}
                {isOpenInternal ? (
                  <img src={downIcon.src} />
                ) : (
                  <img src={upIcon.src} />
                )}
              </Accordian>
            )}
          </div>
        )}
      </Header>
      {isOpenInternal ? <Content>{children}</Content> : null}
    </Wrapper>
  );
}
